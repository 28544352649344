import { writable } from 'svelte/store';

export const isFetching = writable(false);

export async function fetchConnectivity(api) {
  isFetching.set(true);

  const HEADERS = {
    'Content-Type': 'application/json;charset=utf-8',
  };

  const res = await fetch(`${api}`, {
    method: 'GET',
    headers: HEADERS,
  });

  console.assert(res.ok);
  if (!res.ok) {
    console.error(`fetchConnectivity failed with status ${res.status}.`);

    switch (res.status) {
      case '401':
        throw 401;
      case '403':
        throw 403;
      case '500':
        throw 500;
      default:
        throw new Error(res.statusText);
    }
  }

  const result = await res.json();

  isFetching.set(false);
  return result;
}
