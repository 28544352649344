<script>
  /**
   * SvelteBoilerplate
   * © 2020, Hendrik Runte
   **/

  import { version } from './lib/version.js';
  import Header from './components/Header.svelte';
  import Revision from './components/Revision.svelte';
  import { fetchConnectivity } from './lib/fetchConnectivity';
  import { onMount } from 'svelte';

  window.console.debug(
    '%c This is assembled with svelte. ',
    'background-color: fuchsia; color: black;'
  );

  const SupervisedAPIs = [
    {
      api: 'https://sp.hendrikrunte.de/api/connectivity',
      title: 'SP',
      result: false,
      elapsed: 0,
    },
    {
      api: 'https://www.runte-sv.de/api/connectivity',
      title: 'runte-sv.de',
      result: false,
      elapsed: 0,
    },
    {
      api: 'https://daylight.hendrikrunte.de/api',
      title: 'Daylight',
      result: false,
      elapsed: 0,
    },
    {
      api: 'https://rezepte.capricorna.de/api/connectivity',
      title: 'rezepte.capricorna.de',
      result: false,
      elapsed: 0,
    },
    {
      api: 'https://fluglizenz.candybgraveller.cc/api/connectivity',
      title: 'Candy B. Graveller',
      result: false,
      elapsed: 0,
    },
    {
      api: 'https://dev.events.cafelust.de/api/connectivity',
      title: 'CafeLust (DEV)',
      result: false,
      elapsed: 0,
    },
    {
      api: 'https://tempo.hendrikrunte.de/api/connectivity',
      title: 'Tempo (PROD)',
      result: false,
      elapsed: 0,
    },
    {
      api: 'https://dev.tempo.hendrikrunte.de/api/connectivity',
      title: 'Tempo (DEV)',
      result: false,
      elapsed: 0,
    },
  ];

  async function check() {
    SupervisedAPIs.forEach(async (supervised, i) => {
      const timerStart = new Date().getTime();
      const result = await fetchConnectivity(supervised.api);
      window.console.debug(`Checking '${supervised.title}'.`);
      const timerEnd = new Date().getTime();
      if (result.Message) {
        SupervisedAPIs[i].result = true;
      }
      SupervisedAPIs[i].elapsed = timerEnd - timerStart;
    });
  }

  onMount(async () => {
    try {
      check();
      window.setInterval(() => {
        check();
      }, 60000);
    } catch (e) {
      console.debug(e);
    }
  });
</script>

<Header title="Supervisor" subtitle="Active APIs" />
<main>
  {#each SupervisedAPIs as supervised}
    <dl>
      <dt>{supervised.title}:</dt>
      <dd>
        <span class="elapsed" style="margin-right: 0.4em"
          >{supervised.elapsed} ms</span
        >
        {#if supervised.result === true}
          <span class="light greenlight" />
        {:else if supervised.result === false}
          <span class="light redlight" />
        {:else}
          <span class="light nolight" />
        {/if}
      </dd>
    </dl>
  {/each}
</main>

<Revision {version} />

<!--
	Mind the 'global' attribute!
	This is needed to let 'prependData' work.
	See rollup.config.js
-->
<style global lang="scss">
</style>
