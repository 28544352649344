<script>
  export let title = 'Just a title';
  export let subtitle = 'Yet another sub-title';
</script>

<header>
  <div class="progress" />
  <h1>{title}</h1>
  <h2>{subtitle}</h2>
</header>
