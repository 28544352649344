<script>
  export let version;
</script>

<style lang="scss">
  section {
    margin-top: 2em;
    display: block;
    color: white;
    font-family: 'Courier New', Courier, monospace;
    font-weight: normal;
    font-size: 1em;
    padding: 0.4em 0;

    p {
      width: 100%;
      margin: 0 auto;
    }
  }
</style>

<section name="revision">
  <p>Revision: {version}</p>
</section>
